import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setOfflineDownloadProgress } from '../../redux/actions/offlineContentPlayer';
import { updateActivityStatus } from '../../redux/actions/downloads';

function ContentFrameContainer({
  title,
  src,
  activityId,
  unitId,
  iframeRef,
  unitsDownloadState,
  updateActivityStatusAction,
  setOfflineDownloadProgressAction,
  className,
  ...props
}) {
  const intervalRef = useRef(null);
  const downloadCheckIntervalMs = 100;

  useEffect(() => {
    // TODO: create enum for success/failure status values
    if (unitsDownloadState[unitId]?.status === 'failed') {
      console.debug(`Unit ${unitId} failed!`);
      clearInterval(intervalRef.current);
    }
  }, [unitsDownloadState[unitId]?.status]);

  const isContentLoaded = contentWindow =>
    contentWindow && !!contentWindow.imagesLoaded && !!contentWindow.videosLoaded;

  const handleIframeLoad = () => {
    intervalRef.current = setInterval(() => {
      try {
        const contentWindow = iframeRef.current?.contentWindow;
        if (isContentLoaded(contentWindow)) {
          clearInterval(intervalRef.current);
          updateActivityStatusAction({ unitId, activityId, status: 'success', lastUpdateTime: Date.now() });
        }
      } catch (error) {
        clearInterval(intervalRef.current);
        updateActivityStatusAction({ unitId, activityId, status: 'failure', lastUpdateTime: Date.now() });
        console.error('Error accessing iframe content:', error);
      }
    }, downloadCheckIntervalMs);

    return () => clearInterval(intervalRef.current);
  };

  return (
    <iframe
      ref={iframeRef}
      onLoad={handleIframeLoad}
      className={className}
      title={title}
      src={src}
      loading="eager"
      {...props}
    />
  );
}

ContentFrameContainer.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  activityId: PropTypes.string,
  unitId: PropTypes.string,
  iframeRef: PropTypes.object,
  unitsDownloadState: PropTypes.object,
  setOfflineDownloadProgressAction: PropTypes.func,
  updateActivityStatusAction: PropTypes.func,
  className: PropTypes.string
};

export default compose(
  connect(
    ({ downloads }) => ({
      unitsDownloadState: downloads?.units || {}
    }),
    {
      setOfflineDownloadProgressAction: setOfflineDownloadProgress,
      updateActivityStatusAction: updateActivityStatus
    }
  )
)(ContentFrameContainer);
