import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../../utils/compose/compose.js';
import styles from './ProductDownloadPage.scss';

import UnitList from '../../../components/UnitList/UnitList.js';
import MultipleContentFrameContainer from '../../../components/MultipleContentFrameContainer/MultipleContentFrameContainer.js';

import { getProductStructureRequest } from '../../../redux/actions/productStructure.js';
import {
  addOfflineProducts,
  addOfflineProfiles,
  addOfflineUnits
} from '../../../redux/actions/offlineContentPlayer.js';
import { EPS_ASSETS_BASE_ELT_MISC } from '../../../../sharedNodeBrowser/constants';
import { getPlatformBaseUrl } from '../../../globals/envSettings.js';
import { getCoverFromBank, getDefaultCoverImage } from '../../../components/OfflineCover/OfflineCover.js';

function ProductDownloadPage({
  units = [],
  storedOfflineUnits,
  getProductStructureRequestAction,
  addOfflineProductsAction,
  addOfflineProfilesAction,
  addOfflineUnitsAction
}) {
  const [contentCode, setContentCode] = useState('');
  const [productIsbn, setProductIsbn] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [activities, setActivities] = useState([]);
  const [iframeData, setIframeData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [data, setData] = useState({});
  const unitId = useRef(null);

  useEffect(() => {
    const handleMessage = event => {
      if (event.data.type === 'PRODUCT_DOWNLOAD_CONTENT_CODE' && event.origin === getPlatformBaseUrl('hub')) {
        setContentCode(event.data.payload.contentCode);
        if (event.data.payload.cptContent) {
          setProductIsbn(event.data.payload.cptContent.isbn);
        }
        setProductTitle(event.data.payload.productTitle);
        setUserId(event.data.payload.userId);
        setData(event.data.payload.cptContent);
        setFirstName(event.data.payload.firstName);
        setLastName(event.data.payload.lastName);
      }
    };

    window.addEventListener('message', handleMessage);
    window.parent.postMessage({ type: 'IFRAME_READY' }, '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (contentCode) {
      getProductStructureRequestAction({ 'content-code': contentCode, depth: '1', platform: 'ELTCORE' });
    }
  }, [contentCode]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const newProductKey = Object.keys(data.offlineProduct)[0];
      const newProduct = { [newProductKey]: Object.values(data.offlineProduct)[0] };

      addOfflineProductsAction([newProduct]);
    }
  }, [data]);

  // TODO: refactor this logic to decrease the number of re-renders
  useEffect(() => {
    if (activities && activities.length > 0) {
      const extractResourceIdAndUrlFromActivity = activities.map(activity => {
        const url = activity.url;
        if (!url || url.length === 0) {
          return {
            message: 'Invalid url'
          };
        }
        const regex = /resources\/(.*?)\/index\.html/;
        const match = url.match(regex);
        const resourceId = match ? match[1] : '';
        let portString = '';
        if (window.location.port !== 80) {
          portString = `:${window.location.port}`;
        }

        return {
          src: `${window.location.protocol}//${window.location.hostname}${portString}${activity.url}?a5_store=false&a5_restore=false`,
          title: resourceId,
          activityId: resourceId,
          unitId: unitId.current
        };
      });
      setIframeData(extractResourceIdAndUrlFromActivity);
    }
  }, [activities]);

  const filteredUnits = units
    .filter(item => item.contentCode === contentCode)
    .filter(item => item.isbn === productIsbn)
    .map(unit => {
      const matchOfflineUnits = storedOfflineUnits.filter(offlineUnit => offlineUnit.id === unit.uId);
      const cptContentUnit = data.contents.children[unit.uId];
      const userIdsArray = matchOfflineUnits.map(offlineUnit => offlineUnit.userId);

      return {
        ...unit,
        hasAnyActivityWithPrintView: cptContentUnit.hasAnyActivityWithPrintView,
        printViewImages: cptContentUnit.printViewImages,
        activities: cptContentUnit.activitiesUrls,
        userId: userIdsArray.length > 0 ? userIdsArray : null
      };
    });

  const storeUnit = unit => {
    const { uId, isbn, levelName } = unit;

    const currentDate = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '');

    const unitToStore = {
      id: uId,
      isbn,
      name: levelName,
      contentCode,
      description: productTitle,
      expire_date: data.offlineProduct[contentCode].expires,
      added_date: currentDate,
      userId,
      printViewImages: unit.printViewImages,
      activities: unit.activities,
      isPrintViewDownloaded: unit.isPrintViewDownloaded
    };

    addOfflineUnitsAction([unitToStore]);
  };

  const storeProfile = () => {
    const newProfile = {
      [userId]: {
        firstName,
        lastName: lastName[0],
        token: data.offlineProduct[contentCode].userData[userId].launchToken,
        token_expiry: data.offlineProduct[contentCode].expires,
        products: [
          {
            contentCode,
            token_expiry: data.offlineProduct[contentCode].expires,
            token: data.offlineProduct[contentCode].userData[userId].launchToken
          }
        ]
      }
    };

    addOfflineProfilesAction([newProfile]);
  };

  const extractActivitiesFromUnit = (uId, content) => {
    const unitActivities = [];

    const traverse = node => {
      if (node.activities && Array.isArray(node.activities)) {
        unitActivities.push(...node.activities);
      }

      if (node.children && typeof node.children === 'object') {
        Object.values(node.children).forEach(child => traverse(child));
      }
    };

    if (content.children && content.children[uId]) {
      traverse(content.children[uId]);
    }

    return unitActivities;
  };

  const downloadDesignPacks = async designPacks => {
    let designPackFiles = [];

    try {
      const fetchPromises = designPacks.map(designPackName =>
        fetch(`${getPlatformBaseUrl('ocp')}/content/author/elt/designpack/${designPackName}/v1/manifest.json`).then(
          response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          }
        )
      );

      const jsonDataArray = await Promise.all(fetchPromises);

      jsonDataArray.forEach((jsonData, index) => {
        const files = jsonData.files
          .filter(item => !['gitPath.txt', 'changelog.txt', 'compile.log'].includes(item))
          .map(item => `${getPlatformBaseUrl('ocp')}/content/author/elt/designpack/${designPacks[index]}/v1/${item}`);

        designPackFiles = designPackFiles.concat(files);
      });
    } catch (error) {
      console.error('Error downloading JSON files:', error);
    }

    return designPackFiles;
  };

  const downloadFonts = async designPacks => {
    let fontFiles = [];

    try {
      const fetchPromises = designPacks.map(designPackName => () => {
        fetch(`https://fonts.oup.com/${designPackName}.manifest.json`).then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        });

        fontFiles.push(`/media/fonts/${designPackName}.css`);
      });

      const jsonDataArray = await Promise.all(fetchPromises);

      jsonDataArray.forEach(jsonData => {
        const files = jsonData.files.map(item => `/media/fonts/${item}`);

        fontFiles = fontFiles.concat(files);
      });
    } catch (error) {
      console.error('Error downloading JSON files:', error);
    }

    return fontFiles;
  };

  const cacheAssets = async (cacheDirectory, urls) => {
    const cache = await caches.open(cacheDirectory);

    await Promise.all(
      urls.map(async url => {
        try {
          await cache.add(url);
        } catch (error) {
          console.error(`Failed to cache ${url}:`, error);
        }
      })
    );
  };

  const handleDownloadCallback = async (selectedUnitId, isPrintViewSelected) => {
    const foundUnit = filteredUnits.find(unit => unit.uId === selectedUnitId);

    foundUnit.isPrintViewDownloaded = false;
    if (isPrintViewSelected) {
      foundUnit.isPrintViewDownloaded = true;
    }

    unitId.current = foundUnit.uId;
    storeUnit(foundUnit);
    storeProfile();
    const productContents = data.offlineProduct[contentCode].product.contents;
    const unitActivities = extractActivitiesFromUnit(foundUnit.uId, productContents);

    setActivities(unitActivities);

    let portString = '';
    if (window.location.port !== 80) {
      portString = `:${window.location.port}`;
    }

    const printViewCache = await caches.open('printView');
    if (isPrintViewSelected) {
      const imagesUrl = foundUnit.printViewImages.map(
        img => `${window.location.protocol}//${window.location.hostname}${portString}${EPS_ASSETS_BASE_ELT_MISC}/${img}`
      );

      await printViewCache.addAll(imagesUrl);
    }

    const coversCache = await caches.open('covers');
    await coversCache.addAll([getCoverFromBank(foundUnit.isbn), getDefaultCoverImage()]);

    const requiredOfflineDesignPacks = ['ELT_Shared', 'ELT_Shared_Primary'];
    const designPackFiles = await downloadDesignPacks(requiredOfflineDesignPacks);
    await cacheAssets('designPacks', designPackFiles);

    const fontFiles = await downloadFonts(requiredOfflineDesignPacks);
    await cacheAssets('fonts', fontFiles);

    const pages = [
      `${getPlatformBaseUrl('ocp')}`,
      `${getPlatformBaseUrl('ocp')}/downloads/${userId}`,
      `${getPlatformBaseUrl('ocp')}/offline-launch/teacher/${contentCode}/${userId}`,
      `${getPlatformBaseUrl('ocp')}${EPS_ASSETS_BASE_ELT_MISC}/${data.splashScreen.landscape}`,
      `${getPlatformBaseUrl('ocp')}${EPS_ASSETS_BASE_ELT_MISC}/${data.splashScreen.portrait}`
    ];
    await cacheAssets('default', pages);
  };

  return (
    <div className={styles.container}>
      <UnitList units={filteredUnits} downloadButton userId={userId} downloadCallback={handleDownloadCallback} />

      {iframeData && iframeData.length > 0 ? (
        <div className={styles.activitiesIframeContainer}>
          <MultipleContentFrameContainer
            frames={iframeData}
            customClassName={styles.activitiesIframe}
            currentFrameIndex={1}
            loadingMode="preload-all"
            persistenceMode="presist-all-frames"
          />
        </div>
      ) : null}
    </div>
  );
}

ProductDownloadPage.propTypes = {
  units: PropTypes.array.isRequired,
  storedOfflineUnits: PropTypes.array.isRequired,
  getProductStructureRequestAction: PropTypes.func.isRequired,
  addOfflineProductsAction: PropTypes.func.isRequired,
  addOfflineProfilesAction: PropTypes.func.isRequired,
  addOfflineUnitsAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ productStructure, offlineContentPlayer }) => ({
      units: productStructure.productStructure,
      storedOfflineUnits: offlineContentPlayer.units
    }),
    {
      getProductStructureRequestAction: getProductStructureRequest,
      addOfflineProductsAction: addOfflineProducts,
      addOfflineProfilesAction: addOfflineProfiles,
      addOfflineUnitsAction: addOfflineUnits
    }
  )
)(ProductDownloadPage);
