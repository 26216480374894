import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox } from '@oup/shared-front-end';
import { compose } from 'redux';
import styles from './UnitList.scss';
import ProgressiveButton, {
  STATUS_STARTED,
  STATUS_ENDED,
  STATUS_NOT_STARTED,
  STATUS_FAILED
} from '../ProgressiveButton/ProgressiveButton';
import { startUnitDownload } from '../../redux/actions/downloads';
import withLocalizedContent from '../../language/withLocalizedContent';

function UnitItem({
  unit = {},
  downloadButton = false,
  downloadCallback,
  userId: loggedUser,
  downloadUnitInProgress,
  setDownloadUnitInProgress,
  offlineUnits,
  unitsDownloadState,
  startUnitDownloadAction,
  localizedContent: { productDownload: localizedContent }
}) {
  const currentUnit = offlineUnits.find(u => u.id === unit.uId);
  const isDownloaded = currentUnit?.isDownloadCompleted;
  let initialStatus = STATUS_NOT_STARTED;
  if (unit.userId && unit.userId.length > 0) {
    if (unit.userId.includes(loggedUser)) {
      initialStatus = isDownloaded ? STATUS_ENDED : STATUS_FAILED;
    } else {
      initialStatus = STATUS_NOT_STARTED;
    }
  }
  const [unitStatus, setUnitStatus] = useState(initialStatus);
  const [isPrintViewSelected, setIsPrintViewSelected] = useState(true);

  const startDownload = () => {
    const activities = {};
    unit.activities.forEach(activity => {
      activities[activity] = { lastUpdateTime: Date.now() };
    });

    startUnitDownloadAction({ unitId: unit?.uId, activities });
  };

  useEffect(() => {
    if (unitsDownloadState?.status === 'completed') {
      setUnitStatus(STATUS_ENDED);
      setDownloadUnitInProgress(null);
    } else if (unitsDownloadState?.status === 'failed') {
      setUnitStatus(STATUS_FAILED);
      setDownloadUnitInProgress(null);
    } else if (unitsDownloadState?.progress >= 0) {
      setUnitStatus(STATUS_STARTED);
      setDownloadUnitInProgress(unitsDownloadState?.unitId);
    }
  }, [unitsDownloadState?.progress, unitsDownloadState?.status, downloadUnitInProgress]);

  return (
    <>
      <div className={styles.unitItem}>
        <div className={styles.unitNameColumn}>
          <div className={styles.unitName}>{unit.levelName}</div>
        </div>
        {unit.size && (
          <div className={styles.sizeColumn}>
            <div className={styles.size}>{unit.size}</div>
          </div>
        )}
        <div className={styles.actionsColumn}>
          {downloadButton && (
            <ProgressiveButton
              initialStatus={unitStatus}
              percent={unitsDownloadState?.progress}
              unitId={unit?.uId}
              isDisabled={downloadUnitInProgress && downloadUnitInProgress !== unit?.uId}
              startCallback={async () => {
                setDownloadUnitInProgress(unit.uId);
                setUnitStatus(STATUS_STARTED);
                startDownload();
                await downloadCallback(unit.uId, isPrintViewSelected);
              }}
            />
          )}
        </div>
      </div>
      {unit.hasAnyActivityWithPrintView && !unit.userId?.includes(loggedUser) && (
        <div className={styles.printViewCheckboxContainer}>
          <div className={styles.printViewCheckboxSubContainer}>
            <Checkbox
              key={`${unit.uid}-checkbox`}
              label={localizedContent.include_print_pages}
              value={isPrintViewSelected}
              checked={isPrintViewSelected}
              onChange={() => setIsPrintViewSelected(!isPrintViewSelected)}
            />
          </div>
        </div>
      )}
    </>
  );
}

const arePropsEqual = (prevProps, nextProps) =>
  prevProps.unitsDownloadState?.progress === nextProps.unitsDownloadState?.progress &&
  prevProps.unitsDownloadState?.status === nextProps.unitsDownloadState?.status &&
  prevProps.downloadUnitInProgress === nextProps.downloadUnitInProgress;

UnitItem.propTypes = {
  unit: PropTypes.object,
  downloadButton: PropTypes.bool,
  downloadCallback: PropTypes.func,
  userId: PropTypes.string,
  downloadUnitInProgress: PropTypes.string,
  setDownloadUnitInProgress: PropTypes.func,
  offlineUnits: PropTypes.array,
  unitsDownloadState: PropTypes.object,
  startUnitDownloadAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

const makeMapStateToProps = (initialState, ownProps) => {
  const mapStateToProps = ({ downloads, offlineContentPlayer }) => ({
    offlineUnits: offlineContentPlayer.units,
    unitsDownloadState: downloads.units[ownProps.unit.uId]
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  startUnitDownloadAction: startUnitDownload
};

export default compose(withLocalizedContent('productDownload'))(
  connect(makeMapStateToProps, mapDispatchToProps)(memo(UnitItem, arePropsEqual))
);
