import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener.js';

import OCPLayout from './OCPLayout';
import NotFoundView from '../views/errors/NotFoundView.js';

import StructuredContentPlayerBlankPage from '../routes/BlankPage/StructuredContentPlayerBlankPage.js';
import MyDownloadsPage from '../routes/Offline/MyDownloadsPage/MyDownloadsPage.js';
import ProductDownloadPage from '../routes/Offline/ProductDownloadPage/ProductDownloadPage.js';
import OcpDemo from '../routes/OcpDemo/OcpDemo.js';
import OfflineUserProfiles from '../routes/Offline/OfflineUserProfiles/OfflineUserProfiles.js';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Routes>
          <Route
            path="/"
            element={
              <OCPLayout>
                <OfflineUserProfiles />
              </OCPLayout>
            }
          />
          <Route
            path="/offline-launch/:role/:contentCode/:userId"
            element={
              <OCPLayout>
                <StructuredContentPlayerBlankPage />
              </OCPLayout>
            }
          />
          <Route
            path="/downloads/:userId"
            element={
              <OCPLayout>
                <MyDownloadsPage />
              </OCPLayout>
            }
          />
          <Route
            path="/downloads-embedded"
            element={
              <OCPLayout embedded>
                <MyDownloadsPage />
              </OCPLayout>
            }
          />
          <Route
            path="/product-download"
            element={
              <OCPLayout embedded>
                <ProductDownloadPage />
              </OCPLayout>
            }
          />
          <Route
            path="/offline-demo"
            element={
              <OCPLayout>
                <OcpDemo />
              </OCPLayout>
            }
          />
          <Route
            path="*"
            element={
              <OCPLayout>
                <NotFoundView />
              </OCPLayout>
            }
          />
        </Routes>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
