import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import { ICON_DOWNLOAD, ICON_LOADING, ICON_CHECK_CIRCLE, ICON_REFRESH } from '@oup/shared-front-end/svg/oup';
import withLocalizedContent from '../../language/withLocalizedContent';

export const STATUS_NOT_STARTED = 'not started';
export const STATUS_STARTED = 'started';
export const STATUS_ENDED = 'ended';
export const STATUS_FAILED = 'failed';

function ProgressiveButton({
  startCallback,
  initialStatus,
  labels,
  icons,
  percent = 0,
  isDisabled,
  localizedContent: { progressiveButton: content }
}) {
  const { labelNotStarted = null, labelStarted = null, labelEnded = null, labelFailed = null } = labels || {};
  const { iconNotStarted = null, iconStarted = null, iconEnded = null, iconFailed = null } = icons || {};

  let label;
  let icon;
  if (initialStatus === STATUS_STARTED) {
    label = labelStarted || content.default_label_started;
    label += ` - ${percent}%`;
    icon = iconStarted || { component: <ICON_LOADING /> };
  } else if (initialStatus === STATUS_ENDED) {
    label = labelEnded || content.default_label_ended;
    icon = iconEnded || { component: <ICON_CHECK_CIRCLE /> };
  } else if (initialStatus === STATUS_FAILED) {
    label = labelFailed || content.default_label_failed;
    icon = iconFailed || { component: <ICON_REFRESH /> };
  } else {
    label = labelNotStarted || content.default_label_not_started;
    icon = iconNotStarted || { component: <ICON_DOWNLOAD /> };
  }

  const isButtonClickable = initialStatus !== STATUS_ENDED && initialStatus !== STATUS_STARTED;
  const shouldDisableButton = isButtonClickable && isDisabled;

  const handleButtonClick = () => {
    if (isButtonClickable) {
      startCallback();
    }
  };
  return (
    <Button
      disabled={shouldDisableButton}
      variant="transparent"
      size="small"
      text={label}
      icon={icon}
      onClick={handleButtonClick}
    />
  );
}

ProgressiveButton.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  startCallback: PropTypes.func.isRequired,
  initialStatus: PropTypes.oneOf([STATUS_NOT_STARTED, STATUS_ENDED]),
  labels: PropTypes.object,
  icons: PropTypes.object,
  percent: PropTypes.number,
  isDisabled: PropTypes.bool
};

export default withLocalizedContent('progressiveButton')(ProgressiveButton);
