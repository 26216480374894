import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ICON_LEFT } from '@oup/shared-front-end/svg/oup';
import { useParams } from 'react-router-dom';
import compose from '../../../utils/compose/compose.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import MyDownloads from '../../../components/MyDownloads/MyDownloads';
import OfflineUpdate from '../OfflineUpdate/OfflineUpdate';
import styles from './MyDownloadsPage.scss';
import { MODAL_CLOSE_LINK_KEY } from '../../../globals/cptConstants';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';

function MyDownloadsPage({ localizedContent: { myDownloadsPage: content } }) {
  const [key] = useState('');
  const { userId } = useParams();

  useEffect(() => {
    sessionStorage.setItem(MODAL_CLOSE_LINK_KEY, window.location.pathname);
    preventBodyScroll(false);
  }, [userId]);

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.backButton}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        <ICON_LEFT className={styles.backIcon} />
        {content.back}
      </button>

      <h1 className={styles.title}>{content.title}</h1>
      <MyDownloads key={key} userId={userId} />
      <OfflineUpdate />
    </div>
  );
}

MyDownloadsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('myDownloadsPage'))(MyDownloadsPage);
