import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../../utils/compose/compose.js';

import { updateOfflinePlayerVersion } from '../../../redux/actions/offlineContentPlayer.js';
import { isLocal } from '../../../globals/envSettings.js';

function OfflineUpdate({ environment, storedVersionSha, updateOfflinePlayerVersionAction }) {
  const [needUpdate, setNeedUpdate] = useState(false);

  const updateOfflinePlayer = async () => {
    const filesToBeUpdated = ['bundle', 'settings', 'environment', 'status', 'loading', 'webpack-dev-server'];

    caches.open('default').then(cache => {
      cache.keys().then(cacheList => {
        cacheList.forEach(cacheItem => {
          filesToBeUpdated.forEach(string => {
            if (cacheItem.url.search(string) !== -1) {
              // Temporary disable offline update system
              // cache.delete(cacheItem);
            }
          });
        });

        setNeedUpdate(true);
      });
    });
  };

  const checkIfUpdateIsNeeded = (newVersion, existingVersion) => {
    if (newVersion !== existingVersion) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (environment.about) {
      if (checkIfUpdateIsNeeded(environment.about.VersionSHA, storedVersionSha)) {
        updateOfflinePlayerVersionAction(environment.about.VersionSHA);
        updateOfflinePlayer();
      }
    }
  }, [environment, storedVersionSha]);

  const iframeUrl = isLocal()
    ? `http://${window.location.hostname}:${window.location.port}`
    : `https://${window.location.hostname}`;

  return needUpdate ? <iframe src={iframeUrl} title="updateIframe" style={{ visibility: 'hidden' }} /> : '';
}

OfflineUpdate.propTypes = {
  environment: PropTypes.object,
  storedVersionSha: PropTypes.string,
  updateOfflinePlayerVersionAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ app: { environment }, offlineContentPlayer }) => ({
      environment,
      storedVersionSha: offlineContentPlayer.version
    }),
    {
      updateOfflinePlayerVersionAction: updateOfflinePlayerVersion
    }
  )
)(OfflineUpdate);
