import * as t from '../actionTypes';

export const startUnitDownload = payload => ({
  type: t.START_UNIT_DOWNLOAD,
  payload
});

export const updateActivityStatus = payload => ({
  type: t.UPDATE_ACTIVITY_STATUS,
  payload
});
