import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { OCP_PENDING_ACTIONS_EVENT_TYPE } from '@oup/shared-node-browser/constants';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Checkbox, Button } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import useMediaQuery from '../../utils/mediaQuery';
import styles from './MyDownloads.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import SearchInput from '../SearchInput/SearchInput';
import HubFilterBar from '../HubFilterBar/HubFilterBar';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import {
  deleteOfflineUnits,
  getOfflineUnits,
  setOfflineLicenseStatusTimer,
  getLicenseStatusRequest,
  addOfflineProfiles,
  loadOfflinePendingActionsRequest,
  getPendingActionsTime,
  setPendingActionsTime,
  clearOfflinePendingActionsRequest,
  deleteOfflineProfiles
} from '../../redux/actions/offlineContentPlayer.js';
import OfflineCover from '../OfflineCover/OfflineCover';
import OfflineModal from '../../routes/Offline/OfflineModal/OfflineModal.js';
import { removeActivities, getActivitiesToRemove, clearCache } from '../../services/ocpHelperMethods';
import { EXPIRED } from '../../globals/cptConstants.js';
import HubEmptyStateRestyle from '../HubEmptyStateRestyle/HubEmptyStateRestyle.js';
import { getPlatformBaseUrl } from '../../globals/envSettings.js';
import InformationBox from '../InformationBox/InformationBox.js';

function hasBeenMoreThanOneDay(pastDate) {
  const now = new Date();
  const diffInMilliseconds = now - pastDate;
  const hoursPassed = diffInMilliseconds / (1000 * 60 * 60);
  return hoursPassed > 24;
}

function MyDownloads({
  localizedContent: { myDownloads: content },
  userId,
  key,
  storedOfflineUnits,
  storedOfflineProfiles,
  getOfflineUnitsAction,
  loadOfflinePendingActionsRequestAction,
  deleteOfflineUnitsAction,
  setPendingActionsTimeAction,
  pendingActionsTime,
  clearOfflinePendingActionsRequestAction,
  contentStatus,
  deleteOfflineProfilesAction,
  setOfflineLicenseStatusTimerAction,
  storedOfflineLicenseStatusTimer,
  getLicenseStatusRequestAction,
  offlineLicenses,
  addOfflineProfilesAction
}) {
  const [units, setUnits] = useState('');
  const [searchWord, setSearchWord] = useState(undefined);
  const [searchMode, setSearchMode] = useState(false);
  const [sortOption, setSortOption] = useState('added_date');
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [selectedIds, setSelectedIds] = useState(new Set());
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const offlineUserId = userId;

  useEffect(() => {
    if (window.navigator.onLine) {
      const shouldCallAPI =
        !pendingActionsTime ||
        !pendingActionsTime[userId] ||
        hasBeenMoreThanOneDay(new Date(pendingActionsTime[userId]));

      if (shouldCallAPI && storedOfflineProfiles && storedOfflineProfiles[userId]) {
        loadOfflinePendingActionsRequestAction({
          userToken: storedOfflineProfiles[userId].token
        });

        setPendingActionsTimeAction({ userId, currentTime: new Date() });
      }
    }
  }, [pendingActionsTime]);

  useEffect(() => {
    (async () => {
      try {
        if (contentStatus[userId]?.[0] === OCP_PENDING_ACTIONS_EVENT_TYPE.CLEAR_ALL_OFFLINE_CONTENT) {
          // Step 1: Remove from cache
          await clearCache();

          // Step 2: Remove offline_units from redux store / local storage
          deleteOfflineUnitsAction({ shouldClearAll: true });

          // Step 3: Remove offline_profiles from redux store / local storage
          deleteOfflineProfilesAction({ shouldClearAll: true });

          // Step 4: Clear user pending actions from DB
          clearOfflinePendingActionsRequestAction({ userToken: storedOfflineProfiles[userId].token });
        } else if (contentStatus[userId]?.[0] === OCP_PENDING_ACTIONS_EVENT_TYPE.CLEAR_DATA_FOR_USER) {
          // Step 1: Remove from cache
          const activitiesToRemove = getActivitiesToRemove(storedOfflineUnits, userId);
          await removeActivities(activitiesToRemove);

          // Step 2: Remove offline_units from redux store / local storage
          const unitsToRemove = storedOfflineUnits.filter(u => u.userId === userId).map(u => u.id);
          deleteOfflineUnitsAction({ unitsIds: unitsToRemove, userId });

          // Step 3: Remove offline_profiles from redux store / local storage
          deleteOfflineProfilesAction({ profileIds: [userId] });

          // Step 4: Clear user pending actions from DB
          clearOfflinePendingActionsRequestAction({ userToken: storedOfflineProfiles[userId].token });
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [contentStatus]);

  useEffect(() => {
    setUnits(storedOfflineUnits);
  }, [storedOfflineUnits]);

  useEffect(() => {
    let filtered = [];
    if (units && units.length > 0) {
      filtered = units.filter(unit => {
        const isExpired = unit.date ? new Date(unit.date) < new Date() : false;
        return unit.userId === offlineUserId && !isExpired;
      });
    }

    filtered.forEach(unit => {
      const product = storedOfflineProfiles[unit.userId]?.products.find(p => p.contentCode === unit.contentCode);
      if (product) {
        unit.licenseStatus = product.licenseStatus;
        unit.licenseExpirationDate = product.licenseExpirationDate;
      }
    });

    setFilteredUnits(filtered);
  }, [units, storedOfflineProfiles]);

  useEffect(() => {
    const shouldCallOfflineLicenseAPI =
      !storedOfflineLicenseStatusTimer[offlineUserId] ||
      hasBeenMoreThanOneDay(new Date(storedOfflineLicenseStatusTimer[offlineUserId]));

    if (shouldCallOfflineLicenseAPI) {
      getLicenseStatusRequestAction({ storedOfflineProfiles });
      setOfflineLicenseStatusTimerAction({ offlineUserId, currentTime: new Date() });
    }
  }, [storedOfflineLicenseStatusTimer]);

  const updateOfflineProfiles = () => {
    if (offlineLicenses && !offlineLicenses?.storedOfflineProfiles) {
      addOfflineProfilesAction([offlineLicenses]);
    }
  };

  useEffect(() => {
    updateOfflineProfiles();
  }, [offlineLicenses]);

  const allItemIds = useMemo(() => filteredUnits.map(item => item?.id).filter(Boolean), [filteredUnits]);
  const isAllSelected = useMemo(() => allItemIds.length > 0 && allItemIds.every(id => selectedIds.has(id)), [
    allItemIds,
    selectedIds
  ]);

  const dynamicSort = property => {
    let sortOrder = 1;
    let sortProperty = property;
    if (sortProperty[0] === '-') {
      sortOrder = -1;
      sortProperty = sortProperty.substr(1);
    }
    return (a, b) => {
      const result2 = a[sortProperty] > b[sortProperty] ? 1 : 0;
      const result = a[sortProperty] < b[sortProperty] ? -1 : result2;
      return result * sortOrder;
    };
  };

  const reOrder = sortValue => {
    const newUnits = filteredUnits.sort(dynamicSort(sortValue));
    setFilteredUnits(newUnits);
  };

  const handleSearch = () => {
    getOfflineUnitsAction({ searchWord: searchWord.toLowerCase() });
    setSearchMode(true);
  };

  useEffect(() => {
    if (searchWord === '') {
      handleSearch();
    }
  }, [searchWord]);

  const handleCheckboxChange = itemId => {
    setSelectedIds(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(itemId)) {
        newSelected.delete(itemId);
      } else {
        newSelected.add(itemId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIds(new Set());
    } else {
      setSelectedIds(new Set(allItemIds));
    }
  };

  const handleModal = (itemId, isOneSelected) => {
    setCurrentId(itemId);
    if (isOneSelected) {
      setSelectedIds(new Set());
    }
    setIsDeleteModalOpen(prev => !prev);
  };

  const handleDeleteUnit = async () => {
    const activitiesToRemove = [];
    if (selectedIds.size === 0 && currentId) {
      deleteOfflineUnitsAction({
        unitsIds: [...currentId],
        userId
      });
      activitiesToRemove.push(...getActivitiesToRemove(units, userId, currentId));
    } else {
      deleteOfflineUnitsAction({
        unitsIds: [...selectedIds],
        userId
      });
      activitiesToRemove.push(...getActivitiesToRemove(units, userId, [...selectedIds]));
    }
    await removeActivities(activitiesToRemove);
  };

  const hasActiveLicense = item => {
    const expirationDate = new Date(item.licenseExpirationDate);
    if (expirationDate < new Date()) return false;
    if (item.licenseStatus === EXPIRED) {
      return false;
    }

    return true;
  };

  const renderDownloadedProducts = () => (
    <div className={styles.materials}>
      {filteredUnits.map((item, index) => (
        <>
          <hr className={styles.hr} />
          <div className={styles.item} key={index}>
            <div className={styles.unitDataWrapper}>
              <Checkbox value={selectedIds.has(item?.id)} onChange={() => handleCheckboxChange(item?.id)} />
              <div className={classnames(styles.cover, !hasActiveLicense(item) ? styles.disabled : '')}>
                <a href={`/offline-launch/teacher/${item.contentCode}/${offlineUserId}`}>
                  <OfflineCover isbn={item.isbn} alt={item.name} {...(item.cover && { cover: item.cover })} />
                </a>
              </div>
              <div className={classnames(styles.info, !hasActiveLicense(item) ? styles.disabled : '')}>
                <a href={`/offline-launch/teacher/${item.contentCode}/${offlineUserId}`}>
                  <div className={styles.title}>{item.name}</div>
                  <div className={styles.description}>{item.description}</div>
                </a>
              </div>
            </div>
            <div className={styles.actions}>
              <a href="#" onClick={() => handleModal([item.id], true)}>
                <SVGIcon glyph={GLYPHS.ICON_DELETE} />
                {!isMobile && content.delete_item}
              </a>
            </div>
          </div>
        </>
      ))}
    </div>
  );

  return (
    <>
      <div key={key} className={styles.container}>
        <div className={styles.searchingWrapper}>
          <div className={styles.searchBar}>
            <SearchInput
              placeholder={content.search_placeholder}
              onChange={word => {
                setSearchWord(word);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              buttonAction={() => {
                handleSearch();
              }}
            />
          </div>
          <div className={styles.information}>
            <InformationBox title={content.info_message} isTitleHtml />
          </div>
          <div className={styles.filterBar}>
            <HubFilterBar
              key=""
              idPrefix=""
              filterButtonText=""
              overlayLabelText=""
              hasSortButton={false}
              sortOptions={[
                {
                  text: content.sort_by_date,
                  id: `sort1`,
                  name: 'sortDate',
                  value: `added_date:asc`,
                  checked: sortOption === 'added_date',
                  onChange: () => {
                    setSortOption('added_date');
                    reOrder('added_date');
                  }
                },
                {
                  text: content.sort_by_name,
                  id: `sort2`,
                  name: 'sortName',
                  value: `name:asc`,
                  checked: sortOption === 'name',
                  onChange: () => {
                    setSortOption('name');
                    reOrder('name');
                  }
                }
              ]}
              sortValue={`${sortOption}:asc`}
              ariaControls="searchResults"
            />
          </div>
        </div>
        <div className={styles.selectAll}>
          <Checkbox label="Select all" value={isAllSelected} onChange={handleSelectAll} />
          <div className={styles.actions}>
            <Button
              onClick={() => handleModal(selectedIds, false)}
              text={content.delete_all_items}
              variant="filled"
              size="small"
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_DELETE} /> }}
              disabled={!selectedIds.size}
            />
          </div>
        </div>
        {((filteredUnits && filteredUnits.length > 0) || searchMode) && (
          <>
            {renderDownloadedProducts()}
            <OfflineModal
              isOpen={isDeleteModalOpen}
              offlineContent={content}
              closeOfflineModal={() => handleModal()}
              confirmAction={handleDeleteUnit}
            />
          </>
        )}
      </div>
      {(!filteredUnits || (filteredUnits && filteredUnits.length === 0)) && (
        <div className={styles.noDownloads}>
          <HubEmptyStateRestyle
            iconSrc="/static/images/offline/no-downloads.svg"
            title={content.no_downloads_title}
            link={{ text: content.no_downloads_link, link: getPlatformBaseUrl('hub'), position: 'bottom' }}
          />
          <hr className={styles.hr} />
        </div>
      )}
    </>
  );
}

MyDownloads.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userId: PropTypes.string,
  key: PropTypes.number,
  storedOfflineUnits: PropTypes.array,
  storedOfflineProfiles: PropTypes.object,
  getOfflineUnitsAction: PropTypes.func.isRequired,
  loadOfflinePendingActionsRequestAction: PropTypes.func,
  deleteOfflineUnitsAction: PropTypes.func.isRequired,
  getPendingActionsTimeAction: PropTypes.func.isRequired,
  setPendingActionsTimeAction: PropTypes.func.isRequired,
  pendingActionsTime: PropTypes.object,
  clearOfflinePendingActionsRequestAction: PropTypes.func.isRequired,
  contentStatus: PropTypes.object,
  deleteOfflineProfilesAction: PropTypes.func.isRequired,
  getLicenseStatusRequestAction: PropTypes.func.isRequired,
  offlineLicenses: PropTypes.object,
  addOfflineProfilesAction: PropTypes.func,
  setOfflineLicenseStatusTimerAction: PropTypes.func,
  storedOfflineLicenseStatusTimer: PropTypes.object
};

export default compose(
  connect(
    ({ offlineContentPlayer }) => ({
      storedOfflineUnits: offlineContentPlayer.units,
      storedOfflineProfiles: offlineContentPlayer.profiles,
      pendingActionsTime: offlineContentPlayer.pendingActionsTime,
      contentStatus: offlineContentPlayer.contentStatus,
      storedOfflineLicenseStatusTimer: offlineContentPlayer.offlineLicenseStatusTimer,
      offlineLicenses: offlineContentPlayer.licenses
    }),
    {
      getOfflineUnitsAction: getOfflineUnits,
      deleteOfflineUnitsAction: deleteOfflineUnits,
      loadOfflinePendingActionsRequestAction: loadOfflinePendingActionsRequest,
      getPendingActionsTimeAction: getPendingActionsTime,
      setPendingActionsTimeAction: setPendingActionsTime,
      clearOfflinePendingActionsRequestAction: clearOfflinePendingActionsRequest,
      deleteOfflineProfilesAction: deleteOfflineProfiles,
      setOfflineLicenseStatusTimerAction: setOfflineLicenseStatusTimer,
      getLicenseStatusRequestAction: getLicenseStatusRequest,
      addOfflineProfilesAction: addOfflineProfiles
    }
  ),
  withLocalizedContent('myDownloads')
)(MyDownloads);
